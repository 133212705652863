export const instagramID = "instagramIcon";
export const linkedInID = "linkedinIcon";
export const githubID = "githubIcon";
export const emailID = "emailIcon";
export const phoneID = "phoneIcon";

export const mobileSize = 24;
export const laptopSize = 26;

export const darkTheme = "dark";
export const lightTheme = "light";

export const alertVerticalPos = 'top';
export const alertHorizontalPos = 'center';

export const githubLabel="GitHub";
export const linkedInLabel="Linkedin";
export const InstagramLabel="Instagram";
export const SoundLabel="Music";
export const ThemeLabel="Theme";
export const emailLabel="Email Id";
export const phoneLabel="Mobile Number";

export const errorText="error";
export const successText="success";