import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { darkTheme } from "../../assets/common/commonText";
import { slideInBtmAmt } from "./animations";

export const primaryBgColor = "#15181C";
export const whiteBgColor = "#f2f1ef";
export const whiteColor = "#FFFFFF";
export const blackTextColor = "#161513";
export const blueColor = "#075fe4";
export const purpleColor = "#5e3bee";
export const errorColor = "#B00020";
export const successColor = "rgb(56, 142, 60)";
export const navPrimBgClr = "#1a1e23";
export const navSecBgClr = "#f2f1ef";
export const myFont = "Anton";

const MyTypography = styled(Typography)`
  && {
    font-family: ${myFont};
  }
`;

export const MyText = styled(MyTypography)`
  && {
    @media (max-width: 374px) {
      font-size: 18px !important;
    }
    @media (min-width: 375px) and (max-width: 425px) {
      font-size: 18px !important;
    }
    @media (min-width: 425px) and (max-width: 600px) {
      font-size: 20px !important;
    }
    @media (min-width: 600px) and (max-width: 768px) {
      font-size: 20px !important;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 20px !important;
    }
    @media (min-width: 1024px) and (max-width: 1200px) {
      font-size: 20px !important;
    }
    @media (min-width: 1200px) and (max-width: 1400px) {
      font-size: 20px !important;
    }
    @media (min-width: 1400px) and (max-width: 1536px) {
      font-size: 20px !important;
    }
    @media (min-width: 1536px) {
      font-size: 20px !important;
    }
  }
`;

export const HeaderText = styled(MyTypography)`
  && {
    font-size: 2.5rem;
    font-weight: bold;
    color: ${(props) =>
      props.newtheme === darkTheme ? blueColor : purpleColor};
  }
`;

export const GeneralText = styled(MyTypography)`
  && {
  }
`;

export const StyledContainer = styled(Container)`
  && {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20vh 5vw 20vh 5vw;
  }
`;

export const BottomContainer = styled(Container)`
  && {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyleDiv = styled.div`
  padding-top: 3%;
`;

export const StyledList = styled.ul`
  list-style-type: none;
`;

export const ListItems = styled.li`
  &&& {
    cursor: pointer;
    padding: 30% 0%;
  }
`;

export const IconsDiv = styled.div`
  @media (min-width: 900px) {
    display: block;
    position: fixed;
    bottom: 0px;
    padding-bottom: 3.5vh;
    -webkit-animation: ${slideInBtmAmt};
    animation: ${slideInBtmAmt};
  }
  display: none;
`;

export const CommonDiv = styled.div`
  && {
    display: block;
    text-align: left;
  }
`;

export const TagSpan = styled.span`
  color: ${(props) =>
    props.newtheme === darkTheme ? whiteColor : blackTextColor};
`;

export const ColorSpan = styled.span`
  color: ${(props) =>
    props.newtheme === darkTheme ? whiteColor : blackTextColor};
`;

export const CHead = styled(MyTypography)`
  && {
    @media (max-width: 425px) {
      font-size: 32px !important;
    }
    @media (min-width: 425px) and (max-width: 1024px) {
      font-size: 40px !important;
    }
    @media (min-width: 1024px) {
      font-size: 60px !important;
    }
  }
`;
