import React, { useContext } from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";
import {
  StyledContainer,
  MyText,
  CommonDiv,
  HeaderText,
  // blueColor,
  // purpleColor,
} from "../common/commonStyles";
import { Element } from "react-scroll";
import { ThemeContext } from "../../App";
import { Fade } from "react-awesome-reveal";
import { Stack } from "@mui/material";
// import { Radio } from "@mui/material";
// import { darkTheme } from "../../assets/common/commonText";
// import { Circle } from "@mui/icons-material";

const About = () => {
  const { theme } = useContext(ThemeContext);
  // const [value, setValue] = useState(4);

  // const bioOptions = [
  //   {
  //     value: 0,
  //   },
  //   {
  //     value: 1,
  //   },
  //   {
  //     value: 2,
  //   },
  //   {
  //     value: 3,
  //   },
  //   {
  //     value: 4,
  //   },
  // ];
  return (
    <AboutElement id="aboutElement" name="about">
      <AboutContainer id="aboutContainer">
        <HeaderText newtheme={theme}>01. About Me</HeaderText>
        <Stack id="AboutStack" direction="row" spacing={2}>
          <AboutBox id="aboutBox">
            <Fade triggerOnce>
              <CommonDiv>
                <MyText
                  style={{
                    marginTop: "15px",
                    textAlign: "left",
                  }}
                >
                  {/* {value == 0 && <ShortestBio />}
                {value == 1 && <ShortBio />}
                {value == 2 && <Bio />}
                {value == 3 && <LongBio />} */}
                  {/* {value == 4 &&  */}
                  Hi! My name is Yashwanth Nagaraju and I love coding and
                  contemplating on why it hates me. My love for coding started
                  when I was introduced to the world of algorithms and the
                  dreaded world of coding disguised a simple hello world.
                  <br />
                  <br />
                  After a few years of working as Software Engineer I am
                  currently doing my Master&apos;s of Information technology at
                  University of Melbourne to solve much complex algorithms and
                  to relieve my nightmares of missing my assignment deadlines
                  albeit at a wonderful university and city.
                  {/* } */}
                </MyText>
              </CommonDiv>
            </Fade>
          </AboutBox>
        </Stack>
      </AboutContainer>
    </AboutElement>
  );
};

export default About;

// const FieldSet = styled.fieldset`
//   border: none;
//   display: ;
// `;

// const BioOptions = styled.div`
//   display: grid;
//   gap: 1.5rem;
// `;

// const SRadio = styled(Radio)`
//   && {
//   }
// `;

const AboutContainer = styled(StyledContainer)`
  && {
    @media (max-width: 768px) {
      max-width: 90% !important;
      display: flex;
    }
    @media (min-width: 768px) and (max-width: 1200px) {
      max-width: 85% !important;
      display: inline-block;
    }
    @media (min-width: 1200px) {
      padding: 0% 20%
      max-width: 60% !important;
      display: inline-block;
    }
  }
`;

const AboutBox = styled(Box)`
  align-content: baseline;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    padding-right: 2%;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    padding-right: 2%;
  }
  @media (min-width: 1400px) {
    padding-right: 2%;
  }
`;

const AboutElement = styled(Element)``;

// const AboutText = styled(MyText)``;
